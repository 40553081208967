header{
    // @include position(fixed,$top:0,$left:0);
    // z-index: 999;
    // width:100%;   
    // @include media-breakpoint-up(sm){        
    //     padding:15px 0px;
    // }
    // .title-bar{        
    //     @include position(absolute,$top:0,$left:0);
    //     background-color: rgba(white,0.8);
    //     box-shadow: 0px 5px 4.6px 0.5px rgba(0, 0, 0, 0.07);
    //     width:100%;
    //     transition: background 0.6s;
    //     @include media-breakpoint-down(sm){
    //         height: 55px; 
    //         z-index: 100;
    //     }
    //     @include media-breakpoint-up(md){
    //         height: 65px; 
    //     }
    // }
    //mmenu
    .navBox{
        @include position(fixed,$top:0%,$right:0);
        z-index: 990;
        min-width: 280px;
        background-color:rgba($red,0.8) ;
        height: 100vh;
        overflow:auto;
        display: none;
    }
    ul.mmenu{   
        width:100%;
        @include position(absolute,$top:50%,$left:0);
        transform: translateY(-50%);
        // @include media-breakpoint-down(sm){
            
        // }
		// @include media-breakpoint-up(md){
        //     display: flex;
        //     justify-content:flex-end;
        //     align-items: center;            
        // }  
        // @include media-breakpoint-only(md){
        //     padding-right: 20px;
        // }
        // @include media-breakpoint-up(lg){
        //     padding-right: 60px;
        // }
		> li{            
            text-align: center;
            a{
                position: relative;
                font-size: pxToEm(20);
                @include font-color(white,400);
                letter-spacing: 0.5px;
                line-height: 1.2;  
                display: inline-block;
                &:after{
                    content: ' ';
                    display: block;
                    width:100%;
                    height: 1px;
                    background-color: white;
                    margin-top: 3px;
                    opacity: 0;
                    transition: opacity 0.6s;
                }
            }
            @include media-breakpoint-down(sm){                
                a{
                    padding: 10px 0;
                    margin:5px 0;
                    display: block;
                }
            }
            @include media-breakpoint-up(md){
                margin-bottom: 24px;
                a:hover:after,
                &.active a:after{
                    opacity: 1;
                }
            }    
        }
    }//end mmenu    
    
    .menubox{        
        background-color: $red;
        color:white;
        text-align: center;
        @include position(fixed,$right:0,$top:0);
        z-index: 999;
        cursor: pointer;
        opacity: 1;
        transition:opacity;
        -webkit-overflow-scrolling: touch;
        @include media-breakpoint-down(xs){
            width:50px;
            height:50px;
            font-size: pxToEm(14);
            padding:14px 0 ;
        }
        @include media-breakpoint-up(sm){
            width:80px;
            height: 80px;
            font-size: pxToEm(16);
            letter-spacing: 0.4px;
            line-height: 36px;
            padding:22px 0 ;
        }
    }
    .menuicon{
        @include position(fixed,$right:0,$top:0);
        z-index: 998;
        .mpsrel{
            @include media-breakpoint-down(xs){                
                width:50px;
                height: 50px;
            }
            @include media-breakpoint-up(sm){
                width:80px;
                height: 80px;
            }
        }
        .bar,.bar:after,.bar:before{
            transition: all .25s;
            content: "";
            position: absolute;
            left: 50%;	  
            top:50%;
            height: 2px;
            width: 26px;
            background:white;
            transform: translateX(-50%);
        }
        .bar{
            background:rgba(black,0);
            &:before{
                top:0px;
                transform: translateX(-50%) rotate(-45deg);
                background-color: white;
    
            }
            &:after{
                top:0px;
                transform: translateX(-50%) rotate(45deg);
                background-color: white;
    
            }
        }
        // .bar{
        //     margin-top: 18px;
        // }
        // .bar:after{top: 10px;}
        // .bar:before{top: -10px;}	
		
		// @include media-breakpoint-up(md){
		// 	display: none;
		// }
    }
    // //mobile meun open
    &.menu-expanded{
        .menubox{
            opacity: 0;
        }
        // .title-bar{
        //     background-color: white;
        // }
        // .menuicon .bar{
        //     background:rgba(black,0);
        //     &:before{
        //         top:0px;
        //         transform: translateX(-50%) rotate(-45deg);
        //         background-color: black;
    
        //     }
        //     &:after{
        //         top:0px;
        //         transform: translateX(-50%) rotate(45deg);
        //         background-color: black;
    
        //     }
        // }
    }
    // .logo{
    //     transform: translateY(-50%);
    //     @include media-breakpoint-down(md){
    //         @include position(absolute,$top:50%,$left:20px);
    //         width:70px;
    //     }
    //     @include media-breakpoint-up(lg){
    //         @include position(absolute,$top:50%,$left:60px);
    //         width:80px;
    //     }
    // }
    // @include media-breakpoint-down(sm){        
    //     .navBox{
    //         display: none;
    //         width:100%;           
    //         height:calc(100vh - 55px);
    //         position: fixed;
	// 		top:55px;
	// 		left:0;
    //         z-index: 99;
    //         background-color: rgba(white,1);  
	// 		-webkit-overflow-scrolling: touch;
    //     }
    // }
}
