//common
.mtitle{
    h2{
        font-weight: 700;
        @include media-breakpoint-down(xs){
            font-size: pxToEm(28);
        }
        @include media-breakpoint-up(sm){
            font-size: pxToEm(41);
            letter-spacing: 2px;
        }
    }
    .unline:after{
        content: ' ';
        display: block;
        margin-left: 0.5em;
        margin-top: -0.8em;
        @include media-breakpoint-down(xs){
            height: 20px;
            width:123px;
        }
        @include media-breakpoint-up(sm){
            height: 30px;
            width:183px;
        }
    }
}
.temp{
    width:100%;
    max-width: 1200px;
    margin:0 auto;
    display: flex;
    position: relative;
    padding:0 15px;
}
.menuTop{
    width:100%;    
    @include media-breakpoint-up(sm){
        transform: translateY(-25px);
    }
}
#vf{
    transform: translateY(-140px);
}
.indx-banner{
    .slider-banner{
        @include media-breakpoint-down(sm){
            height: calc(100vh - 59px);
        }
        @include media-breakpoint-only(md){
            height: 70vh;
        }
        @include media-breakpoint-up(lg){
            height: 100vh;
            padding:40px 0 0 40px;
        }
    }
    .slick-list,
    .slick-slide > div,
    .slick-track,
    .bgcover{
        height: 100%;
    }
    .txtbox{
        @include media-breakpoint-down(md){
            @include position(absolute,$left:0,$top:0);
            background-color:rgba( #fceade,0.6);
            width:100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        @include media-breakpoint-up(lg){
            width:39vw;
            height: calc(100% - 100px);
            background-color: #fceade;
            @include position(absolute,$left:40px,$top:40px);
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
    .mflex{
        
        img{
            width: 170px;
        }
        @include media-breakpoint-down(sm){
        }
        @include media-breakpoint-up(lg){            
            max-width: 380px;
            flex:0 0 380px;
        }
        @media (min-width: 1400px){
            transform: scale(1.5);
            transform-origin: right;
        }
    }
    .redbg{
        @include font-color(white);
        background-color: $red;
        line-height: 1;
        @include media-breakpoint-down(xs){
            font-size: pxToEm(20);
            padding:10px;
            margin:10px 0;
        }
        @include media-breakpoint-up(sm){
            margin:20px 0 28px;
            font-size: pxToEm(28);
            letter-spacing: 1.4px;
            padding:10px 0 10px 20px;
        }
    }
    .txt{
        @include font-color(#512205,500);
        @include media-breakpoint-down(xs){
            font-size: pxToEm(16);
        }
        @include media-breakpoint-up(sm){
            letter-spacing: 0.58px;
            line-height: 1.5;
            font-size: pxToEm(24);
        }
    }
    //slick
    .slick-list{
        @include media-breakpoint-down(md){
        }
        @include media-breakpoint-up(lg){            
            width:60%;
            margin-left: 40%;
        }
    }
    .slick-slide .bgcover{
        transform: scale(1);
        transition: transform 6s;
    }
    .slickinit .slick-slide.slick-active .bgcover{
        transform: scale(1.15);
    }
    .custom_paging{
        @include position(absolute,$bottom:0);
        z-index: 10;
        
        @include media-breakpoint-down(xs){
            left:0px;
            height: 40px;
        }
        @include media-breakpoint-up(sm){      
            left:40px;      
            height: 60px;
        }
        @include media-breakpoint-up(lg){  
            width:39vw;
        }
        // max-width: 530px;
        background-color: #fceade;
        text-align: center;
        @include font-color(black,700);
        li{
            opacity: 0;
            position: absolute;
            @include position(absolute,$top:0,$left:0);
            letter-spacing: 4px;
            background-color:white;
            @include media-breakpoint-down(xs){
                width:140px;
                height: 40px;
                line-height: 46px;
            }
            @include media-breakpoint-up(sm){  
                width:180px;
                height: 60px;
                line-height: 66px;
            }
        }
        li.slick-active{
            opacity: 1;
        }
        i{
            color:red;
        }
    }
    .btn-slick-prev{
        @include triangle_sosceles(left,6px,9px,$red);
        cursor: pointer;
        z-index: 11;
        @include media-breakpoint-down(xs){
            @include position(absolute,$left:15px,$bottom:11px);
        }
        @include media-breakpoint-up(sm){
            @include position(absolute,$left:60px,$bottom:20px);
        }
    }
    .btn-slick-next{
        @include triangle_sosceles(right,6px,9px,$red);
        cursor: pointer;
        z-index: 11;        
        @include media-breakpoint-down(xs){
            @include position(absolute,$left:115px,$bottom:11px);
        }
        @include media-breakpoint-up(sm){
            @include position(absolute,$left:185px,$bottom:20px);
        }
    }
    .btngroup{
        @include position(absolute,$left:40px,$bottom:0);
        width:180px;
        height: 60px;
    }
}
.va{
    background:no-repeat url(../images/a/a_bg.png) center / cover;
    overflow: hidden;
    .container{
        margin:0 auto; 
        max-width: 100%;
        @include media-breakpoint-down(sm){
            // padding:7% 0 14% 0;
            padding:0;
        }
        @media (min-width:1200px){
            max-width:85%;
        }
        padding:7% 20px 14%;
    }
    .mtitle{
        h2{
            color:$brwon;
        }
        .unline:after{
            background-color: #e9c435;
        }
        @include media-breakpoint-down(sm){
            margin-bottom: 25px;
            padding-left: 0px;
            &.show-d{
                display: none;
            }
        }
        @include media-breakpoint-up(md){
            margin-bottom: 40px;
            &.show-m{
                display: none;
            }
        }
    }
    .mname{
        background-color: $yel;         
        @include font-color($brwon,700);
        @include media-breakpoint-down(sm){
            min-width:100px;
            text-align: center;
            padding:6px 10px;
            font-size: pxToEm(14);
            @include position(absolute,$bottom:0px,$left:0);
        }
        @include media-breakpoint-up(md){
            width:215px;
            padding:12px 20px 8px 20px;
            @include font-color($brwon,700);
            font-size: pxToEm(26);
            @include position(absolute,$left:-8px,$bottom:24px);
        }
    }
    .slickbox{
        @include media-breakpoint-down(sm){
            .slick-dots{
                text-align: right;
                margin-top: -31px;
                padding-right: 10px;
            }
        }
        @include media-breakpoint-up(md){
            width:85%;
            img{
                width:100%;
            }
        }
    }
    .txtbox{
        background-color: #fff2cd;
        @include media-breakpoint-down(sm){
            width:100%;
            padding:20px;
            margin-top: -5px;
        }
        @include media-breakpoint-up(md){
            @include position(absolute,$right:0,$bottom:10%);
            width:475px;
            padding:36px 36px 36px 36px;
        }
        @include media-breakpoint-only(md){
            transform: scale(0.8);
            bottom:0%;
        }
    }
    h5{
        @include font-color(#b08d07,700);
        @include media-breakpoint-down(xs){
            margin-bottom: 18px;
            font-size: pxToEm(20);
        }
        @include media-breakpoint-up(sm){
            margin-bottom: 36px;
            font-size: pxToEm(26);
            line-height: 1.54;
            letter-spacing: 1.3px;
        }
    }
    .txt{
        @include font-color($brwon,700);
        @include media-breakpoint-down(xs){
            font-size: pxToEm(16);
        }
        @include media-breakpoint-up(sm){
            font-size: pxToEm(18);
            letter-spacing: 0.45px;
            line-height: 1.78;
        }
    }
}
//va,vb
.temp1{
    position: relative;
    .txtbox{
        @include media-breakpoint-down(sm){
        }
        @include media-breakpoint-up(md){
            height: 100%;
            width:40%;
            flex:0 0 40%;
            display: flex;
            justify-content: center;
            align-items: center;
            > div{
                width:400px;
            }
        }        
        @include media-breakpoint-only(md){
            > div{
                transform: scale(0.8);
            }
        }
    }    
    .imgbox{
        @include media-breakpoint-down(sm){
        }
        @include media-breakpoint-up(md){            
            flex:0 0 60%;
            width:60%;
            overflow: hidden;
            position: relative;
            img{
                height: 100%;
                max-width: initial;
            }
        }
    }
    .mtitle{
        position: relative;        
        @include media-breakpoint-down(sm){
            margin-bottom: 25px;
            padding-left: 10px;
        }
        @include media-breakpoint-up(md){
            margin-bottom: 55px;
            padding-left: 10px;
        }
        h2{
            color:$brwon;
            @include media-breakpoint-down(xs){
                margin-bottom: 12px;
            }
            @include media-breakpoint-up(sm){
                margin-bottom: 20px;
            }
        }
        h2 + div{
            @include font-color($brwon,700);
            @include media-breakpoint-down(xs){
                font-size: pxToEm(20);
            }
            @include media-breakpoint-up(sm){
                font-size: pxToEm(26);
                letter-spacing: 1.3px;
            }
        }
        &:before{
            content: '';
            width:1px;
            height: 90%;
            @include position(absolute,$top:8px,$left:0);
            background-color: $brwon;
        }
    }
    .txt{
        @include font-color(#441212,700);
        @include media-breakpoint-down(xs){
            font-size: pxToEm(16);
        }
        @include media-breakpoint-up(sm){
            line-height: 1.78;
            letter-spacing: 0.45px;
            font-size: pxToEm(18);
        }
    }
}
.vb{
    overflow: hidden;
    @include media-breakpoint-down(sm){
    }
    @include media-breakpoint-up(md){  
        padding-left: 40px;
    }
    .temp1{
        @include media-breakpoint-down(sm){
        }
        @include media-breakpoint-up(md){            
            display: flex;
            justify-content: flex-end;
        }
    }
    .txtbox{
        background:no-repeat url(../images/b/b_txtbg.png) center / cover;
        @include media-breakpoint-down(sm){
            padding:20px;
            &.show-d{
                display: none;
            }
        }
        @include media-breakpoint-up(md){            
            @include position(absolute,$left:0px,$top:0);
            &.show-d{
                display: none;
            }
        }
        @include media-breakpoint-only(md){
            padding:20px;
        }
    }
    .mtitle{
        .unline:after{
            background-color: #f0a2a8;
        }
    }
    .imgbox{
        > div{
            height: 100%;
            display: flex;
            overflow: hidden;
            @include position(absolute,$left:50%);
            transform: translateX(-50%);
            animation: image-move 15s linear infinite;
        }
        img{
            height: 100%;
            max-width: initial;
        }
        @include media-breakpoint-down(sm){               
            height: 250px;     
            width: 100%;
            overflow: hidden;    
            position: relative;            
        }
        @include media-breakpoint-up(md){
            height: 40vw;
        }
        @include media-breakpoint-between(sm,md){
            height: 60vw;
        }
    }
}
.vc{
    overflow: hidden;
    @include media-breakpoint-down(sm){
        // margin-top: 10px;
        // margin-bottom: 60px;
    }
    @include media-breakpoint-up(md){
        padding-right: 40px;
        margin-top: 10px;
        margin-bottom: 120px;
    }
    .txtbox{
        background:no-repeat url(../images/c/c_txtbg.png) center / cover;
        @include media-breakpoint-down(sm){
            padding:20px;    
            margin-top: -5px;
        }
        @include media-breakpoint-up(md){
            @include position(absolute,$right:0px,$top:0);
        }
        @include media-breakpoint-only(md){
            padding:20px;
        }
    }
    .mtitle{
        .unline:after{
            background-color: #e9c435;
        }
    }
    .slickbox{
        .slick-dots{
            text-align: right;
            padding-right:20px;
            
            @include media-breakpoint-down(sm){
                margin-top: -31px;
            }
        }
        @include media-breakpoint-only(md){            
            img{
                width:auto;
                height: 500px;
            }
        }
        @include media-breakpoint-up(lg){            
            img{
                width:100%;
            }
        }
    }
}
.vd{
    
    @include media-breakpoint-down(sm){
        margin: 60px 0 60px;
    }
    
    @include media-breakpoint-up(md){        
        margin: 0 0 120px;
    }
    position: relative;
    .mtitle{
        h2{
            color:black;
        }
        h2 + div{
            @include font-color($red,500);
            line-height: 1.78;
            letter-spacing: 0.45px;
            margin-top: 24px;
            @include media-breakpoint-down(xs){
                font-size: pxToEm(16);
            }
            @include media-breakpoint-up(sm){
                font-size: pxToEm(18);
            }
        }
        h2 + div i{
            color:black;
            @include media-breakpoint-down(xs){
                height: 1px;
                color: white;
                overflow: hidden;
                display: block;
            }
            @include media-breakpoint-up(sm){
            }
        }
    }
    .imgbox{
        div{
            padding:0 15px;
        }
        @include media-breakpoint-down(xs){
            margin-top: 24px;
            margin-bottom: 50px;
        }
        @include media-breakpoint-up(sm){
            margin-top: 48px;
            margin-bottom: 100px;
        }
    }
    .imgbg{
        background:no-repeat url(../images/d/d_imgbg.png) center bottom / 2000px;
        @include position(absolute,$bottom:0,$left:0);
        width:100%;
        height: 100%;
        z-index: -1;
    }
}
.ve{
    @include media-breakpoint-down(sm){
        margin:0 0 40px;
    }
    @include media-breakpoint-up(md){
        margin:0 0 220px;
    }
    .mtitle{
        h2{
            color:black;
        }
        h2 + div{
            @include font-color($red,700);             
            @include media-breakpoint-down(xs){ 
                margin-top: 12px;
                font-size: pxToEm(16); 
            }
            @include media-breakpoint-up(sm){  
                margin-top: 24px;
                font-size: pxToEm(26); 
                line-height: 1.54;
                letter-spacing: 1.3px;
            }
            @include media-breakpoint-between(sm,md){
                font-size: pxToEm(20); 
            }
        }
        h2 + div i{
            width:1px;
            background-color: $red;
            display: inline-block;
            @include media-breakpoint-down(xs){
                height: 13px;
                margin:0 8px;
            }
            @include media-breakpoint-up(sm){  
                height: 2em;
                margin:0 30px;
                vertical-align: -0.5em;
            }
        }
    }    
    .temp-e{
        @include media-breakpoint-down(sm){
            margin-top: 25px;            
        }
        @include media-breakpoint-up(md){            
            display: flex;
            margin-top: 65px;
        }
    }
    .txtbox{
        background:no-repeat url(../images/e/e_txtbg.png) right center;
        
        @include media-breakpoint-down(sm){
            padding:20px 15px;
        }
        @include media-breakpoint-up(md){
            width:39%;
            flex:0 0 39%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            > div{
                width:320px;
                margin-right: 10%;
            }
        }
        @include media-breakpoint-only(md){
            > div{
                transform: scale(0.8);
            }
        }
    }
    .imgbox{
        @include media-breakpoint-down(sm){
            
        }
        @include media-breakpoint-up(md){
            width:61%;
            flex:0 0 61%;
            img{
                height: 100%;
                max-width: initial;
            }
        }
    }
    h5{
        line-height: 1;
        letter-spacing: 1.3px;
        @include font-color(white,700);
        @include media-breakpoint-down(xs){
            font-size: pxToEm(20);
        }
        @include media-breakpoint-up(sm){           
            font-size: pxToEm(26);
        }
    }
    .mline{
        @include font-color(white,700);
        border-top:1px solid white;
        border-bottom:1px solid white;
        line-height: 1;
        @include media-breakpoint-down(xs){
            font-size: pxToEm(16);
            margin:16px 0 24px;
            padding:12px 0;
        }
        @include media-breakpoint-up(sm){ 
            margin:20px 0 40px;
            padding:6px;
            letter-spacing: 0.5px;
            font-size: pxToEm(20);
        }
    }
    .txt{
        @include font-color(white,500);
        @include media-breakpoint-down(xs){
            font-size: pxToEm(16);
        }
        @include media-breakpoint-up(sm){   
            line-height: 1.78;
            letter-spacing: 0.45px;
            font-size: pxToEm(18);
        }
    }
    .advimg{
        @include media-breakpoint-down(sm){
            display: flex;
            justify-content: space-around;
            img{
                margin:0 20px;
            }
        }
        @include media-breakpoint-up(md){
            text-align: center;
            img{
                margin:0 40px 50px 0;
            }
        }
    }
    .itembox{
        background:no-repeat url(../images/e/e_imgbg.jpg) center top / 2000px;
        padding-top: 80px;
    }
    .itembox ul{
        max-width: 1000px;
        margin:0 auto;
        padding:0 15px;
        @include media-breakpoint-down(sm){
            margin-top: 30px;
        }
        @include media-breakpoint-up(md){
            display: flex;
            justify-content: space-around;
        }
        @include font-color(black,500);
        li > div{
            @include media-breakpoint-down(xs){
                margin-bottom:8px;
                font-size: pxToEm(16);
            }
            @include media-breakpoint-up(sm){
                margin-bottom:15px;
                font-size: pxToEm(18);
            }
        }
        span{
            display: inline-block;
            width: 34px;
            height: 34px;
            line-height: 34px;
            background-color: $red;
            color:white;
            margin-right: 6px;
            text-align: center;
            @include border();
        }

    }
}
.vf{    
    position: relative;
    @include media-breakpoint-down(sm){
        overflow: hidden;
        padding-top: 3em;
        padding-bottom: 8%;
    }
    .bg{
        background:no-repeat url(../images/f/f_bg.png) #fceade center bottom / 2000px;
        
        @include media-breakpoint-down(sm){
            padding:24px 0 0px;
            background-size: cover;
        }
        @include media-breakpoint-up(md){
            padding:24px 0 18px;
        }
    }
    .mtitle{
        h2{
            color:black;
        }
        h2 + div{
            font-weight: 500;
            .fblack{
                color:black;
            }
            .fred{
                color:$red;
            }
            @include media-breakpoint-down(xs){
                font-size: pxToEm(20);
                margin:8px 0 16px;

            }
            @include media-breakpoint-up(sm){
                font-size: pxToEm(26);
                line-height: 43px;
                letter-spacing: 1.3px;
                margin:12px 0 32px;
            }
        }
    }
    .temp{
        @include media-breakpoint-down(sm){
            transform: translateY(-3em);
        }
        @include media-breakpoint-up(md){
            justify-content: flex-end;
            transform: translateY(-60px);
        }
    }
    .imgbox{
        img{
            @include media-breakpoint-down(sm){
                transform: scale(1.3);
                margin-top: 10px;
            }
            @include media-breakpoint-up(md){
                height: 100%;
                max-width: initial;
            }
        }
        @include media-breakpoint-up(md){
            @include position(absolute,$right:55%,$top:-35%);
            transform: translateX(390px);
        }
        @include media-breakpoint-only(md){
            top:0;
            right:66%;
            img{
                transform: scale(0.9);
            }
        }
    }
    .txtbox{
        max-width: 390px;
    }
    .txt{
        @include font-color(black,500);
        @include media-breakpoint-down(xs){
            font-size: pxToEm(16);
        }
        @include media-breakpoint-up(sm){
            font-size: pxToEm(18);
            line-height: 32px;
        }
    }
}
.vg{
    position: relative;
    @include media-breakpoint-down(xs){
        margin:60px 0 40px;
        padding-left: 20px;
    }
    @include media-breakpoint-up(sm){
        margin:120px 0 120px;
        padding-left: 20vw;
    }
    .mtitle{
        background:no-repeat url(../images/g/g_imgbg.png) right top / cover ;
        height: 109%;
        margin-top: -3%;
        @include media-breakpoint-down(xs){
            padding-top: 36px;
        }
        @include media-breakpoint-up(sm){
            padding-top: 72px;
        }
        h2{
            color:black;
            @include media-breakpoint-down(xs){
                margin-bottom: 12px;
            }
            @include media-breakpoint-up(sm){
                margin-bottom: 24px;
            }
        }
        h2 + div{
            letter-spacing: 1.3px;
            @include font-color(black,700);
            @include media-breakpoint-down(xs){
                font-size: pxToEm(20);
            }
            @include media-breakpoint-up(sm){
                font-size: pxToEm(26);
                line-height: 40px;
            }
        }
    }
    .temp{
        @include position(absolute,$left:50%,$top:0);
        transform: translateX(-50%);
        height: 100%;
        &:before{
            content:' ';
            @include position(absolute,$top:0);
            background-color: white;
            height: 100%;
            @include media-breakpoint-down(xs){
                margin-top: 30px;
                left:20px;
            }
            @include media-breakpoint-up(sm){
                margin-top: 60px;
                width:25vw;
                left:-20vw;
                margin: -3%;
                height: 110%;
            }
        }
        ul{
            @include media-breakpoint-down(xs){
                margin-top: 30px;
            }
            @include media-breakpoint-up(sm){
                margin-top: 60px;
            }
        }
        li{
            line-height: 1;
           @include font-color($red,700);
           @include media-breakpoint-down(xs){                
                font-size: pxToEm(16);
                margin-bottom: 12px;
            }
            @include media-breakpoint-up(sm){                
                font-size: pxToEm(21);
                margin-bottom: 20px;
                letter-spacing: 0.53px;
            }
           &::before{
                content:' ';
                display: inline-block;
                width:4px;
                height: 18px;
                background-color: $red;
                margin-right: 12px;
                vertical-align: -2px;
           }
        }
    }
}
.vh{
    .mtitle{
        transform: translateY(1.5em);
        position: relative;
        z-index: 1;
        h2{
            text-align: center;
            color:black;
        }
    }
    .temp{
        padding:60px 0px 40px; 
        background:no-repeat url(../images/h/h_bg.png) #fceade center top / 1200px;
    }
    form.row{
        margin:0 auto;
        width:100%
    }
}
.f_info{
    .temp{
        justify-content: space-around;
        transform: translateY(80px);
        a{
            margin-right: 2%;
            box-shadow: 2.8px 9.6px 7.8px 0.2px rgba(28, 16, 5, 0.21);
            background-color: $red;
            text-align: center;
            font-size: pxToEm(20);
            letter-spacing: 1px;
            line-height: 1.6;
            @include font-color(white,500);
            // height: 160px;
            width:23%;
            flex:0 0 23%;
            padding:30px 12px;
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
        }
        a:last-child{
            margin-right: 0;
            padding:24px 0;
        }
        a:before{
            content:"";
            @include position(absolute,$top:0,$left:-100%);
            width:100%;
            height: 100%; 
            background:no-repeat url(../images/hover.png) center / cover;
            transition: left 0.6s;
        }
        @include media-breakpoint-down(sm){
            display: none;
        }
        @include media-breakpoint-up(md){
            a:hover:before{
                left: 0;
                top:0;
            }
        }
        a > div{
            position: relative;
            z-index: 10;
            margin:auto;
        }
        .txt{
            margin-top: 16px;
        }
        .txt div{
            font-size: pxToEm(16);
            margin-top: 8px;
        }
    }
    #map{
        height: 418px;
        width:100%;
        background:no-repeat url(../images/i_img1.png) center center / 2000px;
        @include media-breakpoint-down(xs){
            background-size: cover;
        }
    }
    .infobtn{
        @include media-breakpoint-down(sm){
            @include position(fixed,$bottom:0);
            width:100%;
            z-index: 99;
            display: flex;
            justify-content: space-around;
            a{
                background-color: $red;
                flex:0 0 25%;
                border:1px solid rgba(white,0);
                text-align: center;
                padding:18px 12px;
                font-size: pxToEm(14);
                @include font-color(white);
            }
        }
        @include media-breakpoint-up(md){
            display: none;
        }
    }
}
footer{
    background-color: $red;
    padding:32px 15px 24px;
    @include media-breakpoint-down(sm){
        padding-bottom: 60px;
    }
    .mtitle{
        h2{
            color:white;
            margin-bottom: 16px;
        }
    }
    .temp{
        max-width: 1200px;
        margin:0 auto;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    li{
        @include font-color(white,500);
        line-height: 1.8;
        @include media-breakpoint-down(xs){
            font-size: pxToEm(14);
            margin-bottom: 8px;
            flex:0 0 100%;
        }
        @include media-breakpoint-up(md){  
            font-size: pxToEm(18);
            margin-bottom: 16px;
        }
        @include media-breakpoint-between(sm,md){   
            width:50%;
            flex:0 0 50%;
        }
        @include media-breakpoint-up(lg){                      
            width:calc(100% / 3);
            flex:0 0 calc(100% / 3);
        }
        &::before{
            content:' ';
            display: inline-block;
            width:4px;
            height: 18px;
            background-color: white;
            margin-right: 12px;
            vertical-align: -2px;
        }
    }
}
@keyframes image-move {
	0% {transform: translatex(-40%);}
	50% {transform: translatex(-60%);}
	100% {transform: translatex(-40%);}
}
//animation
.indx-banner{
    .revealOnScroll:nth-child(2){
        transition-delay: 0.2s;
    }
}
.va{
    &.revealOnScroll{
        transform: translate(0,0); 
    }
    .revealOnScroll:nth-child(2){
        transition-delay: 0.2s;
    }
    .revealOnScroll:nth-child(3){
        transition-delay: 0.5s;
    }

    @include media-breakpoint-down(sm){
        .revealOnScroll.txtbox{
            transform: translate(0,0); 
            opacity: 1;           
        }
        .revealOnScroll.slickbox{
            transform: translate(0,0); 
        }
    }
    @include media-breakpoint-up(md){
        .revealOnScroll.txtbox .revealOnScroll{
            transform: translate(0,0);
            opacity: 1;
        }
    }
}
.vb{
    .revealOnScroll:nth-child(2){
        @include media-breakpoint-up(md){
            transition-delay: 0.2s;
        }
    }
    .revealOnScroll.imgbox{
        transform: translate(0,0);
        transition:opacity 2s;
        @include media-breakpoint-up(md){
            transition-delay: 0.4s;
        }
    }
}
.vc{
    .revealOnScroll:nth-child(2){
        transition-delay: 0.2s;
    }
    .revealOnScroll.slickbox{
        transform: translate(0,0);
        transition:opacity 2s;
        @include media-breakpoint-up(md){
            transition-delay: 0.4s;
        }
    }
}
.vd{
    .revealOnScroll:nth-child(2){
        transition-delay: 0.2s;
    }
    .revealOnScroll.opa{
        transform: translate(0,0);
        transition:opacity 3s;
        @include media-breakpoint-up(md){
            transition-delay: 0.5s;
        }
    }
}
.ve{
    .revealOnScroll:nth-child(2){
        @include media-breakpoint-up(md){
            transition-delay: 0.2s;
        }
    }
    .txtbox .revealOnScroll{
        @include media-breakpoint-down(sm){
            transform: translate(0,0);
        }
        @include media-breakpoint-up(md){
            transition-delay: 0.7s;
        }
    }
    .revealOnScroll.temp-e{
        transform: translate(0,0);
        transition:opacity 3s;
        
        @include media-breakpoint-down(sm){
            transition-delay: 0.2s;
        }
        @include media-breakpoint-up(md){
            transition-delay: 0.2s;
        }
    }
}
.vf{
    .revealOnScroll.mtitle,
    .revealOnScroll.txt{
        @include media-breakpoint-up(md){
            transform: translate(40px,0);
            &.animated{
                opacity: 1;
                transform: translateX(0px);
            }
        }
    }
    .revealOnScroll.imgbox{
        @include media-breakpoint-down(sm){
            transition-delay: 0.2s;
        }
        @include media-breakpoint-up(md){
            transform: translateX(300px);
            &.animated{
                opacity: 1;
                transform: translateX(390px);
            }
        }
    }
}
.vg{
    .revealOnScroll.imgbox{
        transform: translate(0,0);
    }
    .revealOnScroll.mtitle{
        transform: translate(-40px,0);
        &.animated{
            opacity: 1;
            transform: translateX(0px);
        }
    }
}
// footer{
//     .revealOnScroll.temp{
//         transform: translate(0,0);
//     }
// }
