@import url('https://fonts.googleapis.com/css?family=Noto+Sans+TC:300,400,500,700&display=swap');
$mfont:'Noto Sans TC','Microsoft JhengHei', "微軟正黑體", sans-serif;
$fen:'Microsoft JhengHei', "微軟正黑體", sans-serif;


//-------------------------文字顏色-------------------------//
$pink:#f8756b;
$brwon:#512205;
$blue:#00b4cd;
$orange:#ff6e19;
$yel:#e9c435;
$green:#2d4e37;
$gray:#eee;
$fontcolor:#413523;
$red:#9d1f28;
$menu:#ff6e19;
$acolor:#104179;


$maxConentW:1200px;
$maxConentPding:15px;
