.form_des{
    @include font-color(black,300);
    text-align: center;
    @include media-breakpoint-down(xs){
        font-size: pxToEm(14);
        margin-bottom: 25px;
        i{
            display: block;
        }
    }
    @include media-breakpoint-up(sm){
        font-size: pxToEm(18);
        letter-spacing: 0.9px;
        margin-bottom: 35px;
    }
}
form{
    overflow: hidden; 
    letter-spacing: 0.75px;
    input,select,textarea{
        outline: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        box-shadow:none;
        width:100%;
        background-color:white;
        font-size: pxToEm(18);
        @include font-color(black,300);
        &:focus { outline: none; }
    }
    input,select{
        height:48px;
        padding:0 20px;
        padding:0px 20px;
        border: solid 1px #e5e5e5;
        @include border();
        @include placeholder {
            font-size: pxToEm(18);
            @include font-color(black,#9f9f9f);
            letter-spacing: 0.9px;
        }
    }
    //select
    select::-ms-expand { display: none; }    
    select{
        background:url('../images/select_down.png') white no-repeat calc(100% - 20px) 55% / 12px;
        padding:3px 15px 3px 20px ;
        outline: none;
    }
    @media screen\0 {
        select{
        background:url("../images/select_down.png") white no-repeat calc(100% - 20px) 55% / 12px;
        }
    }
    label{
        line-height: 1;
        margin-bottom: 16px;
        @include font-color(black,500);
        @include media-breakpoint-down(xs){
            font-size: pxToEm(15); 
            margin-bottom: 12px;
            letter-spacing: 0;
        }
        @include media-breakpoint-up(sm){
            font-size: pxToEm(20); 
        }
    }
    // .form-group{
    //     @include media-breakpoint-up(sm){
    //         margin-bottom: 11px;
    //     }
    // }
    .with-errors{
        flex:0 0 100%;
        min-height:16px;
        line-height:1;
        letter-spacing: 0.8px;
        @include font-color($red);  
        display:block;
        margin-top: 4px;
        margin-bottom: 11px;
        @include media-breakpoint-down(xs){
            font-size: pxToEm(14); 
        }
        @include media-breakpoint-up(sm){
            font-size: pxToEm(16); 
        }
    }
    a[type="submit"]{
        outline: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        box-shadow:none;
        line-height: 48px;
        height: 48px;
        background-color: $red;
        @include border();
        text-align: center;
        @include font-color(white,700);
        letter-spacing: 1px;
        font-size: pxToEm(20);
        padding:0 36px;
        margin-top: 15px;
        display: inline-block;
        &:hover{
            background-color: white;
            color:$red;
        }
    }
    //agree
    .check_radi {
        padding-left: 36px;
        line-height: 22px;
        text-align: left;
        width: auto;
        position: relative;
        cursor: pointer;
        user-select: none;
        @include media-breakpoint-down(xs){
            padding-left: 30px;
        }
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            left: 0;
            top:0;
            z-index: 5;
            width:24px;
            height: 24px;
        }
        .checkmark {
            @include position(absolute,$top:0,$left:0);
            height: 24px;
            width: 24px;
            background-color: white;
            border: solid 1px #e5e5e5;
            @include media-breakpoint-down(xs){
                // transform: translateY(-50%);
                // transform-origin: left;
                font-size: pxToEm(15);
            }
            // @include media-breakpoint-up(sm){
            //     transform: translateY(-50%);
            // }
        }
        .checkmark:after {
            content: "";
            @include position(absolute,$top:-5px,$left:5px);
            display: none;
            width: 24px;
            height: 24px;
            background: url('../images/agree_yes.png') no-repeat center / contain ;
        }
        input:checked~.checkmark:after {
            display: block;
        }
        a[data-popup]{
            color:$red;
        }
    }
    textarea{
        min-height: 241px;
    }
    .textarea-wrapper{
        height: 284px;
        overflow: hidden;
        padding:20px 11px 15px 15px;
        border: solid 1px #e5e5e5;
        background-color: white;
        @include border(24px);
        textarea{
            overflow: hidden;
            border:none;
            padding:0;
            text-rendering: auto;
            -webkit-writing-mode: horizontal-tb !important;
            cursor:text;
        }
    }
}